<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }} </strong>
        <slot name="area"></slot>
        <slot name="period"></slot>
        <slot name="fiscalYear"></slot>
      </span>
      <div>
        <CButton
          color="light"
          class="gtm-tag-export"
          @click="exportHTMLToExcel"
        >
          {{ messages.tables.export.toUpperCase() }}
          <LogoExcel />
        </CButton>
      </div>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        outlined
        border
        :table-filter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
        clickableRows
        id="excelArea"
        :fields="tableFields"
        :items="tableItems"
      >
        <template #colSpan="{ item }">
          <td :colspan="item.colSpan"></td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import formatNum from "@/utils/formatNum";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
import LogoExcel from "@/components/atoms/LogoExcel";
export default {
  name: "TableTrackingDc",
  components: {
    LogoExcel,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    requestParams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    tableFields() {
      const cells = [
        {
          key: "family",
          label: this.messages.trackingDc.dcFamily.toUpperCase(),
        },
        { key: "descriptionCode", label: "DC" },
        {
          key: "description",
          label: this.messages.trackingDc.dcDescription.toUpperCase(),
        },
        {
          key: "invoicing",
          label: this.messages.trackingDc.invoicing.toUpperCase(),
        },
        { key: "uio", label: "€/UIO" },
        {
          key: "nationalAverageUio",
          label: "€/UIO MN",
        },
        {
          key: "efficiency",
          label: this.messages.trackingDc.efficiency.toUpperCase(),
        },
        {
          key: "lastFyInvoicing",
          label: this.messages.trackingDc.previousYearInvoicing.toUpperCase(),
        },
        {
          key: "lastFyUio",
          label: "€/UIO AA",
        },
        {
          key: "uioComparison",
          label: "% vs AA",
        },
      ];

      return cells;
    },
    tableItems() {
      this.tableData.map((item) => {
        for (let key in item) {
          if (item[key] === null) {
            item[key] = "-";
          }
        }
        let cellClasses = {};
        cellClasses = {
          ...cellClasses,
          uioComparison: `${this.getEfficiencyClass(
            item.uioComparison
          )} percentage`,
          efficiency: `${this.getEfficiencyClass(item.efficiency)} percentage`,
          invoicing: "eur",
          uio: "eur",
          nationalAverageUio: "eur",
          lastFyInvoicing: "eur",
          lastFyUio: "eur",
        };
        item._cellClasses = cellClasses;
        for (const cell in cellClasses) {
          if (cellClasses[cell].includes("eur") && !isNaN(item[`${cell}`]) && isFinite(item[`${cell}`]) ) {
            item[`${cell}`] = `${formatNum.get(item[cell], 2)}€`;
          } else if (
            cellClasses[cell].includes("percentage") &&
            !isNaN(item[`${cell}`]) &&
            isFinite(item[`${cell}`])
          ) {
            item[`${cell}`] = `${formatNum.get(item[cell], 2)}%`;
          } else if (isNaN(item[`${cell}`]) || !isFinite(item[`${cell}`])) {
            item[`${cell}`] = "-";
          }
        }
      });
      return this.tableData;
    },
  },
  methods: {
    exportHTMLToExcel() {
      ExportHTML2Excel(
        document.querySelector("#excelArea table.table"),
        this.messages.trackingDc.title
      );
    },
    getEfficiencyClass(value) {
      let result = "";
      const number = parseInt(formatNum.get(value, 2).replace(".", ""));
      if (number < 100) {
        result = "result--low";
      } else {
        result = "result--high";
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
thead {
  .thead-top {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: #666666;
    height: 32px;
    vertical-align: middle;
    border-left: 1px solid #dddddd;
  }
  .headCell {
    font-weight: bold;
    background: #dddddd;
    height: 32px;
    vertical-align: middle;
    text-align: center;

    &.dealer {
      text-align: left;
    }
  }
}

.totals-ew-sc {
  font-weight: 700;
  border-bottom: 1px solid #d8dbe0;

  td:first-child {
    border-right-color: transparent;
  }
}
$low: #da2840;
$medium: #fac928;
$high: #60d1b1;

.result {
  &--low {
    color: $low;
  }
}
</style>
