<template>
  <div>
    <CRow>
      <CCol sm="3" class="d-flex pt-1">
        <span class="pb-2">Mostrar</span>
      </CCol>
      <CCol sm="5" class="pl-3 pr-3">
        <CSelect
          :options="options"
          :value.sync="selectedOption"
          @change="setOption"
        />
      </CCol>
      <CCol v-if="this.selectedOption !== null" sm="4">
        <CInput
          class="input-number"
          type="number"
          v-model="selectedNumber"
          @input="setValue"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "FilterInvoicing",
  data() {
    return {
      selectedValue: null,
      options: [
        { value: null, label: "Todos" },
        { value: "DESC", label: "Top" },
        { value: "ASC", label: "Bottom" },
      ],
      selectedOption: null,
      selectedNumber: null,
      order: "DESC",
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    setOption() {
      const selectedOption = this.selectedOption;
      this.$store.dispatch("saveFilterOrder", {
        value: selectedOption,
      });
      if (!selectedOption) {
        this.selectedNumber = null;
        this.setValue();
      } else if (this.selectedNumber === null) {
        this.selectedNumber = 10;
        this.setValue(this.selectedNumber);
      }
    },
    setValue() {
      this.$store.dispatch("saveFilterAmountToShow", {
        value:
          this.selectedNumber === null
            ? this.selectedNumber
            : parseInt(this.selectedNumber),
      });
    },
  },
};
</script>
<style lang="scss">
.input-number input {
  text-align: right;
}
</style>
