<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12" xl="12" xxl="8">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <!--                <FiltersAreaCombo :activeElements="activeItems" :pre-selected="preSelectedArea" />-->
                <FiltersAreaCombo
                  :activeElements="activeItems"
                  :all-countries="true"
                />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="12" lg="4" xxl="1">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol sm="12" lg="8" xxl="3">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
              <CCol sm="6" md="6" class="pb-3">
                <label class="filter-label">Service Type</label>
                <FilterServiceType />
              </CCol>
              <CCol sm="6" md="6">
                <label class="filter-label">
                  {{ messages.filters.invoicing }}
                </label>
                <FilterInvoicing />
                <span
                  v-if="invoicingNumberError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.numberRequired }}</span
                >
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow alignHorizontal="between">
              <CCol>
                <CButton type="submit" color="primary">Mostrar</CButton>
              </CCol>
              <CCol class="text-right">
                <CButton
                  color="success"
                  style="white-space: nowrap"
                  @click="downloadFile($event)"
                  >Descargar
                  <LogoExcel />
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <table-tracking-dc
          :table-data="tableData"
          :request-params="requestParams"
        >
        <span slot="area"> {{ selectedArea }} {{ selectedArea && ' - ' }} </span>
        <span slot="period">{{ selectedPeriod }} / </span>
        <span slot="fiscalYear">{{ selectedFY }}</span>
      </table-tracking-dc>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import FiltersAreaCombo from "@/components/organisms/FiltersAreaCombo";
import FilterFY from "@/components/molecules/FilterFY";
import FilterMonthsRange from "@/components/molecules/FilterMonthsRange";
import TableTrackingDc from "@/components/organisms/TableTrackingDc";
import FilterServiceType from "@/components/molecules/FilterServiceType";
import FilterInvoicing from "@/components/molecules/FilterInvoicing";
import Loader from "@/components/atoms/Loader";
import Api from "@/services/Api";
import LogoExcel from "@/components/atoms/LogoExcel";
import { saveAs } from "file-saver";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";
export default {
  name: "TrackingDc",
  components: {
    TableTrackingDc,
    Loader,
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    FilterServiceType,
    FilterInvoicing,
    LogoExcel,
  },
  data() {
    return {
      cardCollapse: true,
      areaError: false,
      showLoader: false,
      showTable: false,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      tableData: null,
      invoicingNumberError: false,
      requestParams: null,
      selectedArea: null,
      selectedFY: null,
      selectedPeriod: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    submitFilterValues(event) {
      event.preventDefault();
      if (this.checkFilterValues()) {
        this.requestParams = this.getParams();
        this.makeApiQuery(this.requestParams);
      }
    },
    checkFilterValues() {
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
        return false;
      } else if (
        (this.$store.getters.getFilterOrder &&
          !this.$store.getters.getFilterAmountToShow) ||
        this.$store.getters.getFilterAmountToShow < 0
      ) {
        this.invoicingNumberError = true;
        this.areaError = false;
        return false;
      } else {
        this.areaError = false;
        this.invoicingNumberError = false;
        return true;
      }
    },
    makeApiQuery(params) {
      // API call sending parameters
      this.showLoader = true;
      this.showTable = false;
      Api.sendPost("marketing/dcTracking", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            console.log('MIREA PARAMS: ', params)
            this.setTableTitle(params)
            // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.tableData = response.data.data;
          }
        }
      });
    },
    getParams() {
      const currentLang = this.$store.getters.getCurrentLang == "es" ? 1 : 2;
      return {
        ...this.$store.getters.getFiltersAreaComboParams,
        fiscalYear: this.$store.getters.getFilterFYValue,
        sinceMonth: this.$store.getters.getFilterMonthSinceValue,
        upToMonth: this.$store.getters.getFilterMonthUpToValue,
        serviceTypes: this.$store.getters.getFilterServiceType,
        orderBy: this.$store.getters.getFilterOrder,
        topNumber: this.$store.getters.getFilterAmountToShow,
        languageId: currentLang,
        excludeBlankUIO: true,
      };
    },
    downloadFile(event) {
      if (this.checkFilterValues()) {
        const button = event.target;
        event.preventDefault();
        button.setAttribute("disabled", "disabled");
        this.requestParams = this.getParams();
        // API call sending parameters
        Api.sendPost(
          "marketing/dcTrackingDetails/download",
          this.requestParams,
          { responseType: "blob" }
        ).then((response) => {
          if (response.status === 200) {
            const fileName =
              response.headers["content-disposition"].split("=")[1];
            const data = response.data;
            if (data) {
              saveAs(data, fileName);
            }
          }
        });
        setTimeout(function () {
          button.removeAttribute("disabled");
        }, 2500);
      }
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFY = formatFiscalYear(params.fiscalYear);
      const sinceMonth = this.messages.filters.months[params.sinceMonth];
      const upToMonth = this.messages.filters.months[params.upToMonth]
      this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
    },
  },
};
</script>
<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #7dc74e;
  border-color: #64ac36;
}
</style>
