<!-- Receives..  -->
<template>
  <div>
    <multiselect
      @input="setValue"
      v-model="selectedOptions"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :searchable="false"
      :placeholder="placeholder"
      deselectLabel="x"
      selectLabel=""
      selectedLabel=""
      label="name"
      track-by="name"
      :preselect-first="false"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Api from "@/services/Api";
export default {
  name: "FilterServiceType",
  components: { Multiselect },

  data: () => {
    return {
      selectedOptions: null,
      options: [],
      lang: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    placeholder() {
      return this.messages.filters.all;
    },
  },
  methods: {
    setValue() {
      const selectedOptions = this.selectedOptions.map(({ value }) => value);
      this.$store.dispatch("saveFilterServiceType", {
        value: selectedOptions,
      });
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    const queryUrl = "sys/filter/serviceTypes";
    Api.sendGet(queryUrl).then((response) => {
      const arrayResult = [];
      response.data.data.forEach((element) => {
        let item = {
          value: element,
          name: element,
        };
        arrayResult.push(item);
      });
      this.options = arrayResult;
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__option--highlight,
.multiselect__tag,
.multiselect__tag-icon:hover {
  background: #7dc74e;
}

.multiselect__tag-icon:hover:after {
  color: #266d4d;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #c3002f;
}

.multiselect__placeholder {
  color: #768192;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect__tags {
  border-radius: 0.25rem;
}
.multiselect--active {
  color: #768192;
  border: 1px solid #ff4471;
  border-radius: 0.25rem;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 99, 71, 0.25);
}
</style>
